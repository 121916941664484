/*@jsx jsx*/
import { jsx , css } from "@emotion/core";
import React, { useState, useEffect } from "react";
import {Flex, useDisclosure, Text, Image, Box, Spinner} from '@chakra-ui/core';
import Footer from "../components/Footer";
import { useNavigate } from "@reach/router"
import Header from "../components/Header";
import useIsMobile from '../utils/useIsMobile';

function preloadImage(src) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
    });
}

function Home() {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadImages = async () => {
            try {
                await Promise.all([
                    preloadImage('/logo.avif'),
                    preloadImage('/memry.webp')
                ]);
                setLoading(false); // Images are loaded, stop showing spinner
            } catch (error) {
                console.error("Failed to load images", error);
                setLoading(false); // Handle error by still hiding the spinner
            }
        };

        loadImages();
    }, []);

    if (loading) {
        // Show spinner while images are being preloaded
        return (
           <Flex minW="100%" minH="100%" justifyContent="center" alignItems="center" direction="column">
            <Spinner thickness='5px' emptyColor='gray.200' color="secondary" size="xl" />
           </Flex>
        );
    }
    
    return (
        <Flex minH='100%' direction="column" bg="#f7f7f7">
          <Header/>
          <Flex flex={1} overflow="hidden" justifyContent={"space-between"} alignItems={"center"} direction="column">
            <Image className="animate__animated animate__fadeIn animate__fast" borderRadius={20} flexGrow={1} mb={[6,6,6,8]} mt={[2,2,2,4]} maxWidth={['87%','87%','87%','25vw']} src={'/memry.webp'} objectFit="cover" maxH="65vh"></Image>
            <Text maxWidth={['349px','349px','349px','400px']} px={4} fontSize={[18,18,18,20]} color="#5d5c5c" textAlign="center" fontWeight="300">Keep your loved ones memory alive with a beautiful and personalised memorial page.</Text>
            <Footer/>
        </Flex>
        </Flex>
    );
}

export default Home;
