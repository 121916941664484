import React, { useEffect, useState } from "react";
import styles from "../styles/KenBurnsSlideshow.module.css";

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ];
  }

  return array;
}

const animations = shuffle([
  "animation1",
  "animation2",
  "animation3",
  "animation4",
  "animation5",
  "animation6",
  "animation7",
  "animation8",
  "animation9",
  "animation10",
  "animation11",
  "animation12",
  "animation13",
  "animation14",
  "animation15",
  "animation16",
  "animation17",
  "animation18"
]);

function KenBurnsSlideshow({ timing = 5000, images }) {
  const [tick, setTick] = useState(0);

  useEffect(() => {
    const x = setInterval(() => {
      setTick((tick) => (tick + 1) % images.length);
    }, timing);
    return () => {
      clearInterval(x);
    };
  }, [timing, images.length]);

  const lastTick = tick === 0 ? images.length - 1 : tick - 1;

  return (
    <div
      className={styles.container}
      style={{
        "--timing": timing
      }}
    >
      {images.map((image, index) => {
        return (
          <div
            key={image}
            className={`${styles.imageContainer} ${
              tick === index ? `${styles.animationFadeIn} ${styles.playing}` : ""
            }`}
          >
            <img
              alt="wow"
              className={`${styles.image} ${
                styles[animations[index % animations.length]]
              } ${index === lastTick ? styles.clear : ""}`}
              src={image}
              loading="lazy"
            />
          </div>
        );
      })}
    </div>
  );
}

export default KenBurnsSlideshow;
