import { useEffect, useState } from 'react';
import useGeoLocation from 'react-ipgeolocation';

const STRIPE_URLS = {
  US: 'https://buy.stripe.com/14keYZ2HiaH4cVyeUX',
  AUS: 'https://buy.stripe.com/3cs2cd81C02qf3GaEM',
  UK: 'https://buy.stripe.com/eVa5op1De7uS2gU8wC',
  Default: 'https://buy.stripe.com/cN22cd6XycPcg7K7sz',
};

const STRIPE_TEST = 'https://buy.stripe.com/test_5kA00p4JB1xj3JKcMM';

const useStripeUrl = () => {
  const [stripeUrl, setStripeUrl] = useState(STRIPE_TEST);
  const location = useGeoLocation();

  useEffect(() => {
    if (process.env.NODE_ENV.includes('development')) {
      setStripeUrl(STRIPE_TEST);
    } else {
      if (location && location.country) {
        setStripeUrl(STRIPE_URLS[location.country] || STRIPE_URLS.Default);
      } else {
        setStripeUrl(STRIPE_URLS.Default);
      }
    }
  }, [location]);

  return stripeUrl;
};

export default useStripeUrl;
