/*@jsx jsx*/
import { jsx, css } from "@emotion/core";
import React, { useState, useEffect,useCallback } from "react";
import { useNavigate } from "@reach/router"
import { Flex } from '@chakra-ui/core';

function Error() {
  const navigate = useNavigate();

  return (
    <Flex id="error" direction="column" h="100%" minHeight="100%" justifyContent="center" alignItems="center" overflow="hidden" bg="#f7f7f7">
      <h1>There was an error, please try again later!</h1>
    </Flex>
  );
}

export default Error;
