const targetUrl = "https://freeimage.host/json";
const proxy = "https://iridescent-silver-headstand.glitch.me/";

export default async function getServerImageUrl(file) {
  const formData = new FormData();
  formData.append('source', file);
  formData.append('type', 'file');
  formData.append('action', 'upload');
  formData.append('timestamp', '1725522941825');
  formData.append('auth_token', '0b7b8e5722fefc43041a86a81e00c0de31534cc8');
  formData.append('nsfw', '0');
  formData.append('album_id', 'GUs2a');

  const maxRetries = 3;
  let attempt = 0;

  while (attempt < maxRetries) {
    try {
      const response = await fetch(proxy + targetUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data && data.image && data.image.image.url) {
        return data.image.image.url;
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      attempt++;
      if (attempt === maxRetries) {
        console.error('Error uploading image after multiple attempts:', error);
        throw error;
      }
      // Wait before retrying
      await new Promise(resolve => setTimeout(resolve, 2000)); // 2 seconds delay
    }
  }
}
