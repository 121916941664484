import {Flex, Button} from "@chakra-ui/core";
import React, { useState, useEffect } from "react";
import 'animate.css';
import { useTranslation } from 'react-i18next';
import useStripeUrl from '../utils/getStripe';

const Footer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const stripeUrl = useStripeUrl();

    // Reset isLoading to false on component mount or page load (from cache)
    useEffect(() => {
        const handlePageShow = () => {
            setIsLoading(false); // Reset loading state when navigating back or page is loaded from cache
        };

        window.addEventListener('pageshow', handlePageShow);

        return () => {
            window.removeEventListener('pageshow', handlePageShow);
        };
    }, []);

    // Reset isLoading to false on component mount
    useEffect(() => {
        setIsLoading(false);
    }, []);

    const handlePay = async () => {
        setIsLoading(true);
         // Delay the redirection by 500ms to show the loading spinner
        setTimeout(() => {
            window.location.href = stripeUrl;
        }, 500);
    };

    return (
        <Flex 
            py={6} 
            pointerEvents='none' 
            bg="f7f7f7" 
            alignItems="center" 
            justify="center" 
            w="100%" 
            direction="column" 
            className="animate__animated animate__slideInUp animate__fast"
        >       
            <Button 
                pointerEvents='all'
                height="50px"
                width={['82vw', '82vw', '18vw', '18vw']}
                rounded="30px"
                fontSize="17px"
                fontWeight="bold"
                variantColor="brand"
                onClick={handlePay}
                isLoading={isLoading}
                // loadingText='PLEASE WAIT'
            >
                {t("checkout").toUpperCase()}
            </Button>
        </Flex>
    );
};

export default Footer;
