/*@jsx jsx*/
import { jsx , css } from "@emotion/core";
import {Flex, Box, Input} from "@chakra-ui/core";
import React, { useState, useEffect } from "react";
import {HiPlusSm} from "react-icons/hi";
import * as keyframes from '../utils/keyframes';
import { secondary } from "../utils/colors";

function UploadSquare({onUploadPhoto,h,w}) {
    const imageUploaderRef = React.useRef();

    const handleImageUpload = async (e) => {
        onUploadPhoto(e);
    };

    return (
            <Flex style={{boxShadow: '0 8px 14px rgb(0 0 0 / 7%)'}}
                  marginLeft={10}
                  marginRight={10}
                  bg="hsla(0,0%,100%,.7)"
                  h={h}
                  w={w}
                  rounded="2000px"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => {imageUploaderRef.current.click();}}
                  cursor="pointer"
                  color='white'
                  css={true? css`animation: ${keyframes.pulse} 1.7s linear infinite;animation-play-state:running; &:hover {animation-play-state:paused;}` : undefined}
            >
                <Box as={HiPlusSm} fontSize={'90px'} css={css`color: ${secondary};`}/>
                <Input value={""} type="file" accept="image/*" onChange={handleImageUpload} multiple={true} ref={imageUploaderRef} display="none"/>
            </Flex>
    );
};

export default UploadSquare;
