import React, {useState} from 'react';
import {Flex, Box, Image,Menu,MenuButton,MenuList,MenuItem, useDisclosure} from '@chakra-ui/core';
import {FiMenu} from "react-icons/fi";
import {HiOutlineChatAlt} from "react-icons/hi";

function Header({}) {

    return (
        <Box width="100%">
            <Flex
                px={6}
                py={4}
                alignItems="center"
                justifyContent="space-between"
                bg="#f7f7f7"
            >
                <Box as={FiMenu} fontSize={["2xl"]}/>
                <Image src={'/logo.avif'} max-width="100px" height="30px"/>
                <a href="javascript:void(Tawk_API.toggle())"><Box as={HiOutlineChatAlt} fontSize={["2xl"]} cursor={"pointer"}/></a>
            </Flex>
        </Box>
    );
};

export default Header;
