/*@jsx jsx*/
import { jsx , css } from "@emotion/core";
import React, { useState, useEffect } from "react";
import {Flex, useDisclosure, Text, Image, Box, Spinner} from '@chakra-ui/core';
import { useNavigate } from "@reach/router"
import airtable from '../utils/airtable';
import getServerImageUrl from '../utils/helpers'
import UploadSquare from '../components/UploadSquare';
import { useTranslation } from 'react-i18next';
import KenBurnsSlideshow from "../components/KenBurnsSlideshow";

function Profile() {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
   
    async function onUploadPhoto(e) {  
      setLoading(true);
      let files = [...e.target.files]; // Convert FileList to Array

      // Check the number of selected files
      if (files.length < 3 || files.length > 20) {
        alert('Please select between 3 and 20 photos.');
        setLoading(false);
        return;
      }

      // Identify invalid files (those exceeding the size limit)
      const invalidFiles = files.filter(file => file.size > 20 * 1024 * 1024);
      
      if (invalidFiles.length > 0) {
        alert(`Some files exceed 20 MB, please select smaller images`);
        setLoading(false);
        return;
      }
  
      // Map each file to a URL
      const imageUrls = files.map(file => URL.createObjectURL(file));
  
      // Check if profile and profile.fields.Pictures are initialized
      if (!profile || !profile.fields.Pictures) {
          // Initialize profile and profile.fields.Pictures if they are null
          setProfile({
              ...profile,
              fields: {
                  ...profile?.fields,
                  Pictures: imageUrls // Set new image URLs
              }
          });
      } else {
          // Append new image URLs to existing Pictures
          setProfile({
              ...profile,
              fields: {
                  ...profile.fields,
                  Pictures: [...profile.fields.Pictures, ...imageUrls] // Append new images
              }
          });
      }

      Promise.all(files.map(file => getServerImageUrl(file)))
        .then((imageServerUrls) => {
          const imageServerUrlsString = imageServerUrls.join(',');
          airtable.base('appEgxdpUuSKjXFh3').table('table').update([
            {
              id: profile.id,
              fields: {
                "Pictures": imageServerUrlsString, 
              }
            }
          ], (error, records) => {
            if (error) {
              console.error('Error updating record:', error);
            } else {
              // Record was updated successfully, you can add further actions here.
              console.log('Record updated successfully:', records);
            }
          });
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);  
          alert('There was an error, please retry later');
        });
  }  

    function getProfileId() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        return params.get('id');
    }

    useEffect(() => {
        async function fetchMyAPI() {
            try {
              const records = await new Promise((resolve, reject) => {
                airtable.base('appEgxdpUuSKjXFh3').table('table')
                  .select({ filterByFormula: `Id = "${getProfileId()}"` })
                  .firstPage((err, records) => {
                    if (err) {
                      reject(err);
                    } else {
                      resolve(records);
                    }
                  });
              });
              if (records.length > 0) {
                setProfile(records[0]);
                if(records[0].fields.Pictures){  
                  const shuffledPictures = records[0].fields.Pictures.split(",").sort(() => Math.random() - 0.5);
                  setProfile(prevProfile => ({
                    ...prevProfile,
                    fields: {
                      ...prevProfile.fields,
                      Pictures: shuffledPictures
                    }
                  }));
                }
                if(records[0].fields.Active.includes('NO')){
                  alert("We noticed that your subscription was previously canceled. To activate your Memry memorial QR code, please subscribe again.")
                  window.location.href = 'https://billing.stripe.com/p/login/28obModJ5d3UakM6oo';
                  return;
                }
              } else {
                alert('We could not found this profile.');
                navigate('/')
                return;
              }
            } catch (err) {
              console.error('Error searching for record:', err);
            } finally {
              setLoading(false); // Set loading to false once the query is complete
            }
          }
          fetchMyAPI();
        }, []);

    if (loading || !profile ) {
        return (
        <Flex
            minW="100%"
            minH="100%"
            justifyContent="center"
            alignItems="center"
            direction="column"
        >
            <Spinner thickness='5px' emptyColor='gray.200' color="secondary" size="xl" />
            <Flex fontSize="lg" justifyContent="center" alignItems="center"mt={4} fontWeight="600" color='rgb(181, 167, 172)' textAlign='center'>{'Please wait'}</Flex>
        </Flex>
        )
    }

    return (
      <Flex minW='100%' minH='100%' direction="column" justifyContent="space-between">
        <Flex flexGrow={1} justifyContent="center" alignItems={ "center"}>
          { !profile.fields.Pictures ? 
            <Flex className="animate__animated animate__fadeIn animate__fast" direction="column" justifyContent="center" alignItems="center">
              <UploadSquare h='170px' w='170px' onUploadPhoto={ event => onUploadPhoto(event) } />
              <Flex w='200px' fontSize="lg" lineHeight='21px' mt={10} fontWeight="600" color='rgb(181, 167, 172)' textAlign='center'>{t('choose')}</Flex>
            </Flex>
            : 
            <Flex className="animate__animated animate__fadeIn">
              <KenBurnsSlideshow images={profile.fields.Pictures} />
            </Flex>
          }
        </Flex>
    </Flex>
    );
}

export default Profile;
