import { Global, css } from '@emotion/core'
import React from "react";
import {Router} from "@reach/router";
import { theme, ThemeProvider, CSSReset } from "@chakra-ui/core";
import Error from "./pages/Error";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import {primary, secondary} from "./utils/colors";

const breakpoints = ["360px", "768px", "1024px", "1440px"];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const newTheme = {
    ...theme,
    breakpoints,
    shadows: { ...theme.shadows, outline: "none"},
    colors: { ...theme.colors, primary: primary, secondary: secondary, brand: {
        50: primary,
        100: primary,
        200: primary,
        300: primary,
        400: primary,
        500: primary,
        600: primary,
        700: primary,
        800: primary,
        900: primary,
      },lightGray: {
        50: '#E4E4E4',
        100: '#E4E4E4',
        200: '#E4E4E4',
        300: '#E4E4E4',
        400: '#E4E4E4',
        500: '#E4E4E4',
        600: '#E4E4E4',
        700: '#E4E4E4',
        800: '#E4E4E4',
        900: '#E4E4E4',
      }},
};

export default () => (
    <ThemeProvider theme={newTheme}>
        <CSSReset />
        <Global styles={css`*,*:focus {outline: none !important;}`}/>
        <Router>
            <Home path="/" />
            <Profile path="profile" />
            <Error path="error" />
        </Router>
    </ThemeProvider>
);
