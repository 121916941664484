import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: {
            "open book": "open book",
            "start animation 1": "Your photobook is ready",
            "start animation 2": "Have a look",
            "product description": "Your memories in a bracelet",
            "first line footer": "Beautiful photos!",
            "second line footer": "Order now and enjoy your memories",
            "checkout" : "order now",
            "payment complete": "Payment Complete",
            "success description" : "Your payment was successful! Due to high demand, we will ship your photo tiles in 7-14 business days.",
            "order number" : "Order Number",
            "choose": "Please pick some photos",
            "white":"White",
            "black":"Black",
            "rose":"Rose",
            "color":"Color",
            "atleast":"Please select at least 4 photos!",
            "deleteButton":"DELETE",
            "cancelButton":"CANCEL",
            "confirmText":"Are you sure you want to delete this photo?",
            "confirmTitle":"Delete photo",
            "thank you" : "Thank you!"
        },
      },
      de: {
        translation: {
            "open book": "offenes Buch",
            "start animation 1": "Dein Fotobuch ist fertig",
            "start animation 2": "Schau mal",
            "product description": "Ihre Erinnerungen in einem Armband",
            "first line footer": "Schöne Fotos!",
            "second line footer": "Bestellen Sie jetzt",
            "checkout" : "Kasse",
            "payment complete": "Zahlung abgeschlossen",
            "success description" : "Ihre Zahlung war erfolgreich! Wir versenden Ihr Fotos innerhalb von 7-14 Werktagen.",
            "order number" : "Bestellnummer",
            "choose": "Bitte wählen Sie ein Bild aus",
            "white":"Weiss",
            "black":"Schwarz",
            "rose":"Rose",
            "color":"Farbe",
            "atleast":"Bitte wählen Sie mindestens 4 Fotos aus!",
            "deleteButton":"LÖSCHEN",
            "cancelButton":"ABBRECHEN",
            "confirmText":"Möchten Sie dieses Foto wirklich löschen?",
            "confirmTitle":"Foto löschen",
            "thank you" : "Danke!"
        },
      },
      fr: {
        translation: {
            "open book": "livre ouvert",
            "start animation 1": "Votre livre photo est prêt",
            "start animation 2": "Jetez un coup d'oeil",
            "product description": "Vos mémoire dans un bracelet",
            "first line footer": "Belles photos!!",
            "second line footer": "Commandez dès maintenant",
            "checkout" : "paiement",
            "payment complete": "Paiement terminé",
            "success description" : "Votre paiement a été effectué avec succès ! Nous expédierons votre photos sous 7 à 14 jours ouvrés.",
            "order number" : "Numéro de commande",
            "choose": "veuillez choisir une image",
            "white":"Blanc",
            "black":"Noir",
            "rose":"Rose",
            "color":"Couleur",
            "atleast":"Veuillez sélectionner au moins 4 photos!",
            "deleteButton":"SUPPRIMER",
            "cancelButton":"ANNULER",
            "confirmText":"Voulez-vous vraiment supprimer cette photo?",
            "confirmTitle":"Supprimer la photo",
            "thank you" : "Merci!"
        },
      },
      es: {
        translation: {
            "open book": "libro abierto",
            "start animation 1": "Tu fotolibro está listo",
            "start animation 2": "Echa un vistazo",
            "product description": "Tus recuerdos en una pulsera",
            "first line footer": "Hermosas fotos!",
            "second line footer": "Ordene ahora y disfrute de sus recuerdos",
            "checkout" : "ordené",
            "payment complete": "Pago Completo",
            "success description" : "Tu pago fue exitoso! Enviaremos tu fotos en 7 a 14 días hábiles.",
            "order number" : "Número de pedido",
            "choose": "Escoge una foto para empezar",
            "white":"Blanco",
            "black":"Nero",
            "rose":"Rosa",
            "color":"Color",
            "atleast":"Selecciona al menos 4 fotos!",
            "deleteButton":"BORRAR",
            "cancelButton":"CANCELAR",
            "confirmText":"¿Está seguro de que desea eliminar esta foto?",
            "confirmTitle":"Borrar foto",
            "thank you" : "Gracias!"
        },
      },
    },
    fallbackLng: "en",
    detection: {
        order: ["navigator"],
      },
  });

export default i18n;
